import { Box, Typography } from '@mui/material';
import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import PageSectionWithImage from '../../../components/Sections/PageSectionWithImage';

export default () => {
  return (
    <PageSectionWithImage
      maxWidth='lg'
      color='white'
      bgimage={
        <StaticImage
          style={{ height: '100%' }}
          layout='fullWidth'
          quality={95}
          src='../../../images/cennik_hero.jpg'
          alt='Cennik'
        />
      }
    >
      <Typography
        variant='h3'
        component='h1'
        textTransform='uppercase'
        sx={{ ':first-line': { fontSize: '140%', fontWeight: 700 } }}
        mb={3}
      >
        Interesuje Cię wysokość inwestycji w system Mediporta?
      </Typography>
      <Box>
        <Typography component={'p'} gutterBottom>
          Określ rodzaj swojej placówki, a pokażemy Ci przybliżoną kwotę współpracy.
        </Typography>
      </Box>
    </PageSectionWithImage>
  );
};
