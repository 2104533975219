export const T = 'T';
export const N = 'N';
export const X = ' ';

export const columns = ['Minimum', 'Polecany', 'Premium'];

export const data = [
  {
    types: ['Gabinet lekarski', 'Pielęgniarki i położne', 'Gabinet fizjoterapeuty'],
    prices: {
      base: [
        [119, 198, 436],
        [119, 198, 298],
        [119, 198, 263]
      ],
      hour: [
        [0, 0, 0],
        [0, 0, 0],
        [0, 0, 0]
      ]
    },
    rows: {
      // Nazwy są uzupełnione spacjami, żeby wyrównać w kolumnach tablice T/N,
      // i nie walczyć z autoformatteerem, które nie można miejscowo wyłączyć.
      'Kartoteki pacjentów    ': [
        [T, T, T],
        [T, T, T],
        [T, T, T]
      ],
      'Dokumentacja wizyt     ': [
        [T, T, T],
        [T, T, T],
        [T, T, T]
      ],
      'Rozliczenie z NFZ      ': [
        [T, T, T],
        [T, T, T],
        [T, T, T]
      ],
      'Zdarzenia medyczne     ': [
        [N, T, T],
        [N, T, T],
        [N, T, T]
      ],
      'Dokumenty EDM          ': [
        [N, T, T],
        [N, T, T],
        [N, T, T]
      ],
      'Wystawianie e-Recepty  ': [
        [N, N, T],
        [N, N, T],
        [X, X, X]
      ],
      'Baza leków BLOZ – Odpłatności': [
        [N, N, T],
        [N, N, T],
        [X, X, X]
      ],
      'Wystawianie e-Skierowań': [
        [N, N, T],
        [X, X, X],
        [X, X, X]
      ],
      'Realizacja e-Skierowań ': [
        [X, X, X],
        [X, X, X],
        [N, N, T]
      ],
      'Wystawianie e-ZLA      ': [
        [N, N, T],
        [X, X, X],
        [X, X, X]
      ],
      'Integracja z AP-KOLCE  ': [
        [X, X, X],
        [X, X, X],
        [X, X, X]
      ]
    }
  },
  {
    types: ['Przychodnia'],
    prices: {
      base: [[299, 478, 832]],
      hour: [[0, 0, 0]]
    },
    rows: {
      'Terminarz wizyt                 ': [[T, T, T]],
      'Kartoteka pacjentów             ': [[T, T, T]],
      'Szablony, formularze i frazy    ': [[T, T, T]],
      'Powiadomienia SMS               ': [[T, T, T]],
      'Rozliczenia NFZ i komercyjne    ': [[T, T, T]],
      'Raporty i sprawozdania          ': [[T, T, T]],
      'Księgi zabiegowe i diagnostyczne': [[T, T, T]],
      'Zdarzenia medyczne i EDM        ': [[N, T, T]],
      'Wystawianie e-Recept            ': [[N, T, T]],
      'Baza leków BLOZ – Odpłatności   ': [[N, T, T]],
      'Wystawianie e-Skierowań         ': [[N, N, T]],
      'Realizacja e-Skierowań          ': [[N, N, T]],
      'Komunikacja z laboratoriami     ': [[N, N, T]],
      'Rejestracja on-line OSOZ        ': [[N, N, T]],
      'Dokumentacja RODO               ': [[N, N, T]]
    }
  }
];
